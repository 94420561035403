import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import { closeModal } from '~modals';
import { useModalStyles } from '~modals/components/Modal.styles';
var JokerBoosterModal = function (_a) {
    var modalName = _a.modalName, _b = _a.isVisible, isVisible = _b === void 0 ? false : _b, handleClose = _a.modalProps.handleClose;
    var dispatch = useDispatch();
    var classes = useModalStyles();
    var t = useTranslation('tip-game').t;
    var handleCancel = function () {
        dispatch(closeModal({ key: modalName }));
    };
    var onButtonClick = function () {
        handleClose === null || handleClose === void 0 ? void 0 : handleClose();
        handleCancel();
    };
    return (React.createElement(Dialog, { open: isVisible, onClose: handleCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", className: classes.root },
        React.createElement(DialogContent, { className: classNames(classes.dialog, classes.dialogContainer) },
            React.createElement("div", { className: classes.gameClassTitle }, t('joker-booster.title')),
            React.createElement("div", { className: classes.joinGameContent },
                React.createElement("div", { className: classes.gameClassItem }, t('joker-booster.p1')),
                React.createElement("div", { className: classes.gameClassItem }, t('joker-booster.p2')),
                React.createElement("div", { className: classes.gameClassItem }, t('joker-booster.p3')),
                React.createElement("div", { className: classes.gameClassItem }, t('joker-booster.p4'))),
            React.createElement(Button, { fullWidth: true, size: "large", color: "primary", variant: "contained", style: { margin: '24px 0' }, onClick: onButtonClick },
                t('got_it'),
                "!"))));
};
export { JokerBoosterModal };
