export var MODALS = {
    CHANGE_ACCOUNT_MODAL: 'CHANGE_ACCOUNT_MODAL',
    CHANGE_EMAIL_MODAL: 'CHANGE_EMAIL_MODAL',
    CONFIRMATION_MODAL: 'CONFIRMATION_MODAL',
    REGISTRATION_ERRORS_MODAL: 'REGISTRATION_ERRORS_MODAL',
    PPMO_MODAL: 'PPMO_MODAL',
    LOGIN_MODAL: 'LOGIN_MODAL',
    REGISTRATION_MODAL: 'REGISTRATION_MODAL',
    ADDING_BET_ERROR_MODAL: 'ADDING_BET_ERROR_MODAL',
    EDIT_PASSWORD: 'EDIT_PASSWORD',
    EDIT_EMAIL: 'EDIT_EMAIL',
    EDIT_QUESTION: 'EDIT_QUESTION',
    EDIT_ACCOUNT: 'EDIT_ACCOUNT',
    ERROR_MODAL: 'ERROR_MODAL',
    SUCCESS_MODAL_NOTIFICATION: 'SUCCESS_MODAL_NOTIFICATION',
    VIDEO_MODAL: 'VIDEO_MODAL',
    RESTORE_PASSWORD: 'RESTORE_PASSWORD',
    RESEND_EMAIL: 'RESEND_EMAIL',
    FAST_PLAY_NOT_AVAILABLE_MODAL: 'FAST_PLAY_NOT_AVAILABLE_MODAL',
    PURCHASE_TICKETS_RESULT_MODAL: 'PURCHASE_TICKETS_RESULT_MODAL',
    JOIN_GAME_DESCRIPTION_MODAL: 'JOIN_GAME_DESCRIPTION_MODAL',
    DELETE_PURCHASED_TICKET_MODAL: 'DELETE_PURCHASED_TICKET_MODAL',
    GAME_CLASS_DESCRIPTION_MODAL: 'GAME_CLASS_DESCRIPTION_MODAL',
    NO_GOOGLE_VIDEO_MODAL: 'NO_GOOGLE_VIDEO_MODAL',
    JOKER_BOOSTER_MODAL: 'JOKER_BOOSTER_MODAL',
};
