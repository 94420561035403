var _a;
import { lazy } from 'react';
import { DeletePurchasedTicketModal } from '~modals/components/DeletePurchasedTicketModal';
import { ChangeAccountModal } from '~pages/my-account/components/modals/ChangeAccountModal';
import { RegistrationErrorModal } from '~pages/registration/components/ModalError';
import { PPMOModal } from '~pages/selectPPMO/components/PPMOModal';
import { ChangeEmailModal } from '~pages/my-account/components/modals/ChangeEmailModal';
import { EditSecurityModal } from '~modals/components/editModals/EditSecurityModal';
import Login from '~pages/login/Login';
import RegistrationModal from './components/RegistrationModal';
import { AddingBetErrorModal, ConfirmationModal, EditEmailModal, EditPasswordModal, ErrorModal, FastPlayNotAvailableModal, GameClassDescriptionModal, JoinGameDescriptionModal, JokerBoosterModal, NoGoogleVideoModal, PurchaseTicketsResultModal, ResendEmail, RestorePassword, SuccessModal, VideoModal, } from './components';
import { MODALS } from './modals';
var EditPersonalDataModal = lazy(function () { return import('./components/editModals/EditPersonalDataModal'); });
export var MODALS_VIEWS = (_a = {},
    _a[MODALS.CHANGE_ACCOUNT_MODAL] = ChangeAccountModal,
    _a[MODALS.LOGIN_MODAL] = Login,
    _a[MODALS.CHANGE_EMAIL_MODAL] = ChangeEmailModal,
    _a[MODALS.CONFIRMATION_MODAL] = ConfirmationModal,
    _a[MODALS.ADDING_BET_ERROR_MODAL] = AddingBetErrorModal,
    _a[MODALS.REGISTRATION_ERRORS_MODAL] = RegistrationErrorModal,
    _a[MODALS.PPMO_MODAL] = PPMOModal,
    _a[MODALS.EDIT_ACCOUNT] = EditPersonalDataModal,
    _a[MODALS.EDIT_PASSWORD] = EditPasswordModal,
    _a[MODALS.EDIT_EMAIL] = EditEmailModal,
    _a[MODALS.EDIT_QUESTION] = EditSecurityModal,
    _a[MODALS.ERROR_MODAL] = ErrorModal,
    _a[MODALS.SUCCESS_MODAL_NOTIFICATION] = SuccessModal,
    _a[MODALS.REGISTRATION_MODAL] = RegistrationModal,
    _a[MODALS.VIDEO_MODAL] = VideoModal,
    _a[MODALS.RESTORE_PASSWORD] = RestorePassword,
    _a[MODALS.RESEND_EMAIL] = ResendEmail,
    _a[MODALS.FAST_PLAY_NOT_AVAILABLE_MODAL] = FastPlayNotAvailableModal,
    _a[MODALS.PURCHASE_TICKETS_RESULT_MODAL] = PurchaseTicketsResultModal,
    _a[MODALS.JOIN_GAME_DESCRIPTION_MODAL] = JoinGameDescriptionModal,
    _a[MODALS.DELETE_PURCHASED_TICKET_MODAL] = DeletePurchasedTicketModal,
    _a[MODALS.GAME_CLASS_DESCRIPTION_MODAL] = GameClassDescriptionModal,
    _a[MODALS.NO_GOOGLE_VIDEO_MODAL] = NoGoogleVideoModal,
    _a[MODALS.JOKER_BOOSTER_MODAL] = JokerBoosterModal,
    _a);
