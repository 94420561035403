export var LOGIN_PRESS = 'LOGIN_PRESS';
export var CLOSE_SPLASH = 'CLOSE_SPLASH';
export var LOGOUT_PRESS = 'LOGOUT_PRESS';
export var SHOW_REWARDED_ADD = 'SHOW_REWARDED_ADD';
export var SHOW_REWARDED_INTERSTITIAL_ADD = 'SHOW_REWARDED_INTERSTITIAL_ADD';
export var SHOW_REWARDED_INTERSTITIAL_BOOSTER_ADD = 'SHOW_REWARDED_INTERSTITIAL_BOOSTER_ADD';
export var SHOW_INTERSTITIAL_ADD = 'SHOW_INTERSTITIAL_ADD';
export var REWARDED_LOADED = 'RN-WEB::REWARDED_LOADED';
export var REWARDED_SUCCESS = 'RN-WEB::REWARDED_SUCCESS';
export var REWARDED_INTERSTITIAL_SUCCESS = 'RN-WEB::REWARDED_INTERSTITIAL_SUCCESS';
export var REWARDED_FAILED = 'RN-WEB::REWARDED_FAILED';
export var REWARDED_INTERSTITIAL_FAILED = 'RN-WEB::REWARDED_INTERSTITIAL_FAILED';
export var GOOGLE_VIDEO_LOADING_FAILED = 'GOOGLE_VIDEO_LOADING_FAILED';
export var KEYBOARD_OPENED = 'KEYBOARD_OPENED';
export var KEYBOARD_CLOSED = 'KEYBOARD_CLOSED';
